exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-azul-process-azul-tsx": () => import("./../../../src/pages/azul/process-azul.tsx" /* webpackChunkName: "component---src-pages-azul-process-azul-tsx" */),
  "component---src-pages-catalogo-tsx": () => import("./../../../src/pages/catalogo.tsx" /* webpackChunkName: "component---src-pages-catalogo-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-politicas-de-seguridad-tsx": () => import("./../../../src/pages/politicas-de-seguridad.tsx" /* webpackChunkName: "component---src-pages-politicas-de-seguridad-tsx" */),
  "component---src-pages-terminos-y-condiciones-tsx": () => import("./../../../src/pages/terminos-y-condiciones.tsx" /* webpackChunkName: "component---src-pages-terminos-y-condiciones-tsx" */),
  "component---src-templates-product-index-tsx": () => import("./../../../src/templates/Product/index.tsx" /* webpackChunkName: "component---src-templates-product-index-tsx" */),
  "component---src-templates-recipe-index-tsx": () => import("./../../../src/templates/Recipe/index.tsx" /* webpackChunkName: "component---src-templates-recipe-index-tsx" */)
}

