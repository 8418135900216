module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locales","languages":["es","en"],"defaultLanguage":"es","siteUrl":"https://casabi.do","i18nextOptions":{"interpolation":{"escapeValue":false}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"casabi","short_name":"casabi","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8a3123036d823a22feca7a9c9e5eb6d6"},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography","omitGoogleFont":true},
    },{
      plugin: require('../plugins/gatsby-plugin-snipcart-advanced-custom/gatsby-browser.js'),
      options: {"plugins":[],"version":"3.2.1","publicApiKey":"MjNlZDkzNTItOWQ1YS00ZjE2LTk1MjYtNmNmYTJlMDRjNjI1NjM3NTgxOTQzNzgxNjM3ODk4","defaultLang":"es","currency":"dop","openCartOnAdd":false,"useSideCart":false,"templatesUrl":"/snipcart/index.html","locales":{"es":{"shipping":{"method":"Lugar de envío"}}}},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    }]
